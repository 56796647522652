import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Quarantine from 'images/blogs/blog-19.png'
import 'components/css/stories.css'
import HostName from '../../data/static/HostNames'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/3PwedengGawinSaBahay`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: '3‌ ‌Bagay ‌Na ‌Pwedeng ‌Gawin ‌Sa ‌Bahay‌ ‌During ‌Quarantine',
  },
  {
    property: 'og:description',
    content:
      'Paano‌ ‌maging‌ ‌productive‌ ‌kahit‌ ‌nasa‌ ‌bahay‌ ‌ka‌ ‌lang?‌ ',
  },
  {
    property: 'og:image',
    content: Quarantine,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet
      title="3‌ ‌Bagay ‌Na ‌Pwedeng ‌Gawin ‌Sa ‌Bahay‌ ‌During ‌Quarantine"
      meta={SITE_META}
    >
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Quarantine} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          3‌ ‌BAGAY‌ ‌NA‌ ‌PWEDENG‌ ‌GAWIN‌ ‌SA‌ ‌BAHAY‌ ‌DURING‌ ‌QUARANTINE‌ ‌
        </Heading>

        <Section mb="sp4">
          <Text>
            Malaking‌ ‌tulong‌ ‌ang‌ ‌manatili‌ ‌sa‌ ‌bahay‌ ‌para‌ ‌mabawasan‌
            ‌ang‌ ‌pagkahawa‌ ‌o‌ ‌pagkalat‌ ‌ng‌ ‌ COVID-19.‌ ‌Kahit‌ ‌tila‌
            ‌natigil‌ ‌ang‌ ‌mundo,‌ ‌hindi‌ ‌ibig‌ ‌sabihin‌ ‌nito‌ ‌ay‌ ‌wala‌
            ‌na‌ ‌tayong‌ ‌pwedeng‌ ‌ gawin‌ ‌mula‌ ‌sa‌ ‌loob‌ ‌ng‌ ‌ating‌
            ‌mga‌ ‌bahay.‌ ‌Staying‌ ‌home‌ ‌can‌ ‌still‌ ‌be‌ ‌fun‌ ‌and‌
            ‌productive,‌ ‌ ka-Digipay!‌ ‌Narito‌ ‌ang‌ ‌ilang‌ ‌mga‌ ‌bagay‌
            ‌na‌ ‌maaari‌ ‌mong‌ ‌pagkaabahalan‌ ‌ngayong‌ ‌may‌ ‌ Enhanced‌
            ‌Community‌ ‌Quarantine.‌ ‌
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            FAMBAM‌ ‌IS‌ ‌THE‌ ‌BOMB!‌
          </Heading>
          <Text>
            Punuin‌ ‌ng‌ ‌family‌ ‌bonding‌ ‌ang‌ ‌panahong‌ ‌ito‌ ‌para‌
            ‌mabawasan‌ ‌ang‌ ‌stress‌ ‌at‌ ‌lungkot‌ ‌na‌ ‌nakikita‌ ‌ natin‌
            ‌sa‌ ‌mga‌ ‌balita.‌ ‌Dahil‌ ‌hindi‌ ‌rin‌ ‌maiiwasang‌ ‌mainip‌
            ‌ng‌ ‌mga‌ ‌bata,‌ ‌isang‌ ‌magandang‌ ‌paraan‌ ‌ ay‌ ‌maglaro‌ ‌ng‌
            ‌indoor‌ ‌games.‌ ‌Pwede‌ ‌mo‌ ‌itong‌ ‌gawing‌ ‌interactive‌ ‌at‌
            ‌educational‌ ‌para‌ ‌natututo‌ ‌ pa‌ ‌rin‌ ‌sila‌ ‌kahit‌ ‌walang‌
            ‌pasok.‌ ‌May‌ ‌paborito‌ ‌ba‌ ‌kayong‌ ‌pagkain‌ ‌na‌ ‌nabibili‌
            ‌lang‌ ‌sa‌ ‌fastfood‌ ‌o‌ ‌ restaurant?‌ ‌Make‌ ‌your‌ ‌own‌
            ‌version!‌ ‌Mas‌ ‌tipid‌ ‌na,‌ ‌may‌ ‌picnic‌ ‌time‌ ‌pa‌ ‌ang‌
            ‌buong‌ ‌pamilya.‌ ‌Make‌ ‌ this‌ ‌time‌ ‌an‌ ‌ultimate‌ ‌bonding‌
            ‌experience,‌ ‌ka-Digipay!‌ ‌
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            EXTRA‌ ‌RICE‌ ‌O‌ ‌EXERCISE?‌
          </Heading>
          <Text>
            Pwede‌ ‌naman‌ ‌both!‌ ‌Hindi‌ ‌masamang‌ ‌mapasobra‌ ‌ang‌ ‌kain‌
            ‌paminsan-minsan‌ ‌basta‌ ‌healthy‌ ‌ito.‌ ‌ Lalo‌ ‌na‌ ‌ngayong‌
            ‌may‌ ‌COVID-19,‌ ‌mas‌ ‌kailangan‌ ‌nating‌ ‌i-boost‌ ‌ang‌ ‌ating‌
            ‌immune‌ ‌system‌ ‌upang‌ ‌ may‌ ‌panlaban‌ ‌ang‌ ‌ating‌ ‌katawan.‌
            ‌Bukod‌ ‌sa‌ ‌pagkain‌ ‌ng‌ ‌mainam‌ ‌na‌ ‌prutas‌ ‌at‌ ‌gulay,‌
            ‌ang‌ ‌30‌ ‌ minutes‌ ‌na‌ ‌physical‌ ‌activity‌ ‌kada‌ ‌araw‌ ‌ay‌
            ‌malaking‌ ‌bagay‌ ‌upang‌ ‌lumakas‌ ‌ang‌ ‌resistensya.‌ ‌Ang‌ ‌
            zumba‌ ‌ay‌ ‌isang‌ ‌masaya‌ ‌at‌ ‌effective‌ ‌na‌ ‌exercise‌ ‌para‌
            ‌sa‌ ‌buong‌ ‌pamilya.‌ ‌Tara‌ ‌na‌ ‌at‌ ‌igalaw‌ ‌ang‌ ‌ ating‌
            ‌katawan!‌
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            DIGIPAY‌ ‌ALL‌ ‌THE‌ ‌WAY‌
          </Heading>
          <Text>
            Stuck‌ ‌sa‌ ‌bahay?‌ ‌No‌ ‌problem!‌ ‌Just‌ ‌sign-up‌ ‌as‌ ‌Digipay‌
            ‌Agent,‌ ‌top-up‌ ‌with‌ ‌a‌ ‌minimum‌ ‌of‌ ‌P100,‌ ‌ and‌ ‌you‌
            ‌can‌ ‌be‌ ‌an‌ ‌authorized‌ ‌payment‌ ‌center‌ ‌ng‌ ‌buong‌
            ‌barangay.‌ ‌Habang‌ ‌tayong‌ ‌lahat‌ ‌ay‌ ‌ nasa‌ ‌quarantine,‌
            ‌isa‌ ‌itong‌ ‌magandang‌ ‌opportunity‌ ‌para‌ ‌magkaroon‌ ‌pa‌
            ‌rin‌ ‌ng‌ ‌extra‌ ‌income‌ ‌ through‌ ‌bills‌ ‌payment,‌ ‌gaming‌
            ‌pins,‌ ‌e-load,‌ ‌mobile‌ ‌money‌ ‌at‌ ‌microinsurance.‌
            ‌Convenient‌ ‌na,‌ ‌ hassle-free‌ ‌pa‌ ‌dahil‌ ‌walang‌ ‌pila.‌
            ‌Ang‌ ‌saya,‌ ‌‘di‌ ‌ba?‌
          </Text>
        </Section>

        <Section mb="sp4">
          <Text>
            Ready‌ ‌ka‌ ‌na‌ ‌bang‌ ‌maging‌ ‌Digipay‌ ‌Agent? Click{' '}
            <a
              className="inline-link"
              href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}/sign-up`}
            >
              here
            </a>
            . ‌Hanggang‌ ‌sa‌ ‌muli,‌ ‌ka-Digipay!‌ ‌
          </Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
